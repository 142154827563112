import React, { useState, useEffect, useRef } from 'react';
import './OrderDialog.css';
import Dialog from "@mui/material/Dialog";

//Components
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import ToolButton from "../../../../../../../../reusable/ToolButton/ToolButton";
import DocumentPosition from "../../../../../../../../reusable/DocumentPosition/DocumentPosition";
import CloseIcon from '@mui/icons-material/Close';
import YesCancelDialog from "../../../../../../../../reusable/YesCancelDialog/YesCancelDialog";
import BusinessIcon from '@mui/icons-material/Business';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddIcon from '@mui/icons-material/Add';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDialog from "../../../../../../../../reusable/FileDialog/FileDialog";
import api from "../../../../../../../../api";
import PDFDialog from "../../../../../../../../reusable/PDFEditorDialog/PDFDialog";
import { PDFDocument } from 'pdf-lib';
import FileItem from "../../../../../../../../reusable/FileItem/FileItem";
import Paper from "@mui/material/Paper";
import MenuItem from "@mui/material/MenuItem";
import MoveUpIcon from '@mui/icons-material/MoveUp';
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ConnectedOrdersDialog from "./ConnectedOrdersDialog/ConnectedOrdersDialog";
import DescriptionIcon from '@mui/icons-material/Description';
import GenerateDocumentDialog from "./GenerateDocumentDialog/GenerateDocumentDialog";
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import { Tooltip } from 'react-tooltip'
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import HelperFunctions from "../../../../../../../../reusable/HelperFunctions";
import MultipleMailAdressesContextMenu
    from "../../../../../../../../reusable/MultipleMailAdressesContextMenu/MultipleMailAdressesContextMenu";
import MessageTemplatesDialog from "./MessageTemplatesDialog/MessageTemplatesDialog";

const OrderDialog = (props) => {

    const [order, setOrder] = useState(props.order);
    const [isSaved, setIsSaved] = useState(!props.isNewItem);
    const [fileStorageLocation, setFileStorageLocation] = useState(null);
    const [fileStorageLocations, setFileStorageLocations] = useState([]);
    const [file, setFile] = useState(null);
    const [multipartFile, setMultipartFile] = useState(null);
    const [factoryCertificate, setFactoryCertificate] = useState(null);
    const [isNewCustomer, setIsNewCustomer] = useState(false);
    const [isNewSupplier, setIsNewSupplier] = useState(false);
    const [index, setIndex] = useState(null);
    const [pdfFile, setPdfFile] = useState(null);
    const [pdfFileURL, setPdfFileURL] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [fileStorageLocationKind, setFileStorageLocationKind] = useState(null);
    const [expandDocuments,setExpandDocuments] = useState(false);
    const [expandDeliveryBills, setExpandDeliveryBills] = useState(false);
    const [expandInvoices, setExpandInvoices] = useState(false);
    const [expandCancellationInvoices, setExpandCancellationInvoices] = useState(false);
    const [expandOrderConfirmations, setExpandOrderConfirmations] = useState(false);
    const [expandOffers, setExpandOffers] = useState(false);
    const [expandOrders, setExpandOrders] = useState(false);
    const [expandRequests, setExpandRequests] = useState(false);
    const [expandOthers, setExpandOthers] = useState(false);
    const [newOrderState, setNewOrderState] = useState(false);
    const [locked, setLocked] = useState(props.order && props.order.locked && props.order.locked !== props.user.username);
    const [connectedOrdersSearchWord, setConnectedOrdersSearchWord] = useState(null);
    const [connectedOrdersSearchType, setConnectedOrdersSearchType] = useState("free-search");
    const [connectedOrders, setConnectedOrders] = useState(null);
    const [connectedLoading, setConnectedLoading] = useState(false);
    const [positionDisplay, setPositionDisplay] = useState(true);
    const [positionIndex, setPositionIndex] = useState(null);
    const [mailAdresses, setMailAdresses] = useState(null);
    const [anchorPoint, setAnchorPoint] = useState({x:0,y:0});
    const [mailInformation, setMailInformation] = useState(null);
    const [existingCustomer, setExistingCustomer] = useState(null);

    // Dialog actions
    const [bookInventoryTransactionsWarningDialog, setBookInventoryTransactionsWarningDialog] = useState(false);
    const [generateDeliveryBillWarningDialog, setGenerateDeliveryBillWarningDialog] = useState(false);
    const [bookGoodsMovementEntriesWarningDialog, setBookGoodsMovementEntriesWarningDialog] = useState(false);
    const [bookPaidInvoiceEntriesWarningDialog, setBookPaidInvoiceEntriesWarningDialog] = useState(false);
    const [sendMailDialog, setSendMailDialog] = useState(false);
    const [originalFileDialog, setOriginalFileDialog] = useState(false);
    const [bookGoodsMovementWarningDialog, setBookGoodsMovementWarningDialog] = useState(false);
    const [bookPaidInvoiceWarningDialog, setBookPaidInvoiceWarningDialog] = useState(false);
    const [editedFileDialog, setEditedFileDialog] = useState(false);
    const [fileDialog, setFileDialog] = useState(false);
    const [pdfDialog, setPDFDialog] = useState(false);
    const [deleteFileDialog, setDeleteFileDialog] = useState(false);
    const [copyOrderDialog, setCopyOrderDialog] = useState(false);
    const [orderStateOnChangeDialog, setOrderStateOnChangeDialog] = useState(false);
    const [commissionNrDialog, setCommissionNrDialog] = useState(false);
    const [connectedOrdersDialog, setConnectedOrdersDialog] = useState(false);
    const [deleteOriginalFileDialog, setDeleteOriginalFileDialog] = useState(false);
    const [deleteEditedFileDialog, setDeleteEditedFileDialog] = useState(false);
    const [generateDocumentDialog, setGenerateDocumentDialog] = useState(false);
    const [generateInvoiceWarningDialog, setGenerateInvoiceWarningDialog] = useState(false);
    const [unsavedChangesWarningDialog, setUnsavedChangesWarningDialog] = useState(false);
    const [multipleMailAdressesContextMenu, setMultipleMailAdressesContextMenu] = useState(false);
    const [messageTemplatesDialog,setMessageTemplatesDialog] = useState(false);

    useEffect(() => {
        if(props.open) {
            setIsSaved(!props.isNewItem);
        }
        setLocked(props.order && props.user && props.order.locked && props.order.locked !== props.user.username);
    }, [props]);

    const addNewOrder = (item) => {
        props.setLoading(true);
        let url;
        let message;
        switch (props.kind) {
            case 0:
                url = '/customer-orders';
                message = 'Kundenbestellung erfolgreich angelegt';
                break;
            case 1:
                url = '/supplier-orders';
                message = 'Lieferantenbestellung erfolgreich angelegt';
                break;
            default:
                break;
        }
        api.post(url,item)
            .then(response => {
                for(let i = 0; i < item.documentPositions.length; i++) {
                    if(item.documentPositions[i].frontendId) {
                        response.data.documentPositions[i].frontendId = item.documentPositions[i].frontendId;
                    }
                }
                props.addOrderToList(response.data);
                if(item.assignedCustomer && !item.assignedCustomer.id) {
                    props.addCustomerToList(response.data.assignedCustomer);
                }
                if(item.assignedSupplier && !item.assignedSupplier.id) {
                    props.addSupplierToList(response.data.assignedSupplier);
                }
                props.setActiveOrder(response.data);
                props.showMessage(0,message);
                props.setIsNewItem(false);
                setOrder(response.data);
                setIsSaved(true);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const updateOrder = (item,suppressMessages) => {
        props.setLoading(true);
        let url;
        switch (props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        api.put(url,item)
            .then(response => {
                for(let i = 0; i < item.documentPositions.length; i++) {
                    if(item.documentPositions[i].frontendId) {
                        response.data.documentPositions[i].frontendId = item.documentPositions[i].frontendId;
                    }
                }
                if(!suppressMessages) {
                    props.showMessage(0,"Änderungen erfolgreich gespeichert");
                }
                if(item.assignedCustomer && !item.assignedCustomer.id) {
                    props.addCustomerToList(response.data.assignedCustomer);
                }
                if(item.assignedSupplier && !item.assignedSupplier.id) {
                    props.addSupplierToList(response.data.assignedSupplier);
                }
                props.updateOrderInList(response.data);
                props.setActiveOrder(response.data);
                setOrder(response.data);
                setIsSaved(true);
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const openInOutlookOnClickHandler = (e,fileStorageLocation) => {
        if(multipleMailAdressesContextMenu) {
            closeMultipleMailAdressesContextMenu();
        }else{
            let mailAdresses;
            if(props.kind === 0) {
                mailAdresses = order.assignedCustomer.addresses;
            }else if(props.kind === 1) {
                mailAdresses = order.assignedSupplier.addresses;
            }
            for(let i = mailAdresses.length - 1; i >= 0; i--) {
                if(!mailAdresses[i].email) {
                    mailAdresses.splice(i,1);
                }
            }
            let subject;
            switch (fileStorageLocation.kind) {
                case "INVOICE":
                    subject = "Rechnung";
                    break;
                case "DELIVERY_BILL":
                    subject = "Lieferschein";
                    break;
                case "CANCELLATION_INVOICE":
                    subject = "Stornorechnung";
                    break;
                case "FACTORY_CERTIFICATE":
                    subject = "Werkszeugnis";
                    break;
                case "ORDER_CONFIRMATION":
                    subject = "Auftragsbestätigung";
                    break;
                case "ORDER":
                    subject = "Bestellung";
                    break;
                case "OFFER":
                    subject = "Angebot";
                    break;
                case "OTHER":
                    subject = "Dokument";
                    break;
                default:
                    subject = "Dokument"
                    break;
            }
            if(fileStorageLocation.documentNr) {
                subject = subject + " " + fileStorageLocation.documentNr;
                if(fileStorageLocation.documentDate) {
                    subject = subject + " vom " + HelperFunctions.formatDate(fileStorageLocation.documentDate);
                }
            }
            if(order.orderNr) {
                subject = subject + " (Ihre Bestellung " + order.orderNr;
                if(order.orderDate) {
                    subject = subject + " beauftragt am " + HelperFunctions.formatDate(order.orderDate);
                }
                if(order.contactPersonBusinessPartner) {
                    subject = subject + "  von " + order.contactPersonBusinessPartner;
                }
                subject = subject + ")";
            }
            if(order.commissionNr) {
                subject = subject + ", unsere Kommissionsnummer " + order.commissionNr;
            }
            let mailInformation = {
                subject:subject
            }
            if(order.description) {
                mailInformation.body = order.description;
            }
            if(mailAdresses.length === 0) {
                mailInformation.mailAdress = "";
                showMessageTemplatesDialog(mailInformation);
            }
            if(mailAdresses.length === 1) {
                mailInformation.mailAdress = mailAdresses[0];
                showMessageTemplatesDialog(mailInformation);
            }else if(mailAdresses.length > 1) {
                setMailInformation(mailInformation);
                showMultipleMailAdressesContextMenu(e,mailAdresses);
            }
        }
    }

    const orderNrOnChangeHandler = (e) => {
        const updatedOrder = { ...order, orderNr: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, orderDate: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const contactPersonBusinessPartnerOnChangeHandler = (e) => {
        const updatedOrder = { ...order, contactPersonBusinessPartner: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const contactEmailBusinessPartnerOnChangeHandler = (e) => {
        const updatedOrder = { ...order, contactEmailBusinessPartner: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const contactPhoneNumberBusinessPartnerOnChangeHandler = (e) => {
        const updatedOrder = { ...order, contactPhoneNumberBusinessPartner: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const commissionNrOnChangeHandler = (e) => {
        if(e.target.value <= 999999999) {
            const updatedOrder = { ...order, commissionNr: e.target.value };
            setOrder(updatedOrder);
            setIsSaved(false);
        }
    };

    const noteOnChangeHandler = (e) => {
        const updatedOrder = { ...order, note: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const descriptionOnChangeHandler = (e) => {
        const updatedOrder = { ...order, description: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const endDescriptionOnChangeHandler = (e) => {
        const updatedOrder = { ...order, endDescription: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderStateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, orderState: e.target.value };
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            updatedOrder.documentPositions[i].state = e.target.value;
        }
        setOrder(updatedOrder);
        props.showMessage(1,"Der Status der Bestellung wurde geändert und die Positionen entsprechend angepasst.");
        setIsSaved(false);
    };

    const deliveryDateOnChangeHandler = (e) => {
        const updatedOrder = { ...order, deliveryDate: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const itemOnChangeHandler = (e) => {
        const updatedOrder = { ...order, item: e.target.value };
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const firstPositionOnClickHandler = () => {
        if(order.documentPositions && order.documentPositions.length && order.documentPositions.length > 0) {
            setPositionIndex(0);
        }
    }

    const prevPositionOnClickHandler = () => {
        if(positionIndex > 0) {
            setPositionIndex(positionIndex - 1);
        }
    }

    const nextPositionOnClickHandler = () => {
        if(positionIndex < order.documentPositions.length - 1) {
            setPositionIndex(positionIndex + 1);
        }
    }

    const lastPositionOnClickHandler = () => {
        if(order.documentPositions && order.documentPositions.length && order.documentPositions.length > 0) {
            setPositionIndex(order.documentPositions.length - 1);
        }
    }

    const customerOnChangeHandler = (value) => {
        const updatedOrder = { ...order, assignedCustomer: value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const customerNrOnChangeHandler = (e) => {
        let url = "/customers/" + e.target.value
        api.get(url)
            .then(response => {
                setExistingCustomer(response.data);
            })
            .catch(error => {

            });
        const updatedOrder = {
            ...order,
            assignedCustomer: {
                ...order.assignedCustomer,
                nr: e.target.value
            }
        };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const supplierOnChangeHandler = (value) => {
        const updatedOrder = { ...order, assignedSupplier: value };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const supplierNrOnChangeHandler = (e) => {
        const updatedOrder = {
            ...order,
            assignedSupplier: {
                ...order.assignedSupplier,
                nr: e.target.value
            }
        };
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const addOrderPosition = () => {
        const updatedDocumentPositions = [...order.documentPositions];
        const newPosition = {
            frontendId:HelperFunctions.generateRandomString(6),
            state:order.orderState ? order.orderState : props.kind === 0 ? "OFFER_CREATION" : props.kind === 1 ? "OFFER_REQUESTED" : "NO_STATE",
            article:{
                nr:'',
                name:''
            },
            unit:"Stück",
            inventoryCount:1,
            inventoryCountInvoice:0,
            inventoryCountDeliveryBill:0,
            piecePrice:0,
            positionVat:props.company && props.company.defaultVat !== null ? props.company.defaultVat : 0,
            description:"",
            hint:"",
            note:"",
            position:positionDisplay ? updatedDocumentPositions.length + 1 : positionIndex + 2
        }
        updatedDocumentPositions.splice(newPosition.position - 1,0,newPosition);
        if(!positionDisplay) {
            for(let i = newPosition.position; i < updatedDocumentPositions.length; i++) {
                updatedDocumentPositions[i].position++;
            }
        }
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedDocumentPositions)
        });
        setPositionIndex(newPosition.position - 1);
        setIsSaved(false);
    }

    const orderPositionLoadLastPrice = (index) => {
        if(!order.documentPositions[index].article.id || (!order.assignedCustomer || !order.assignedCustomer.id)) {
            props.showMessage(2,"Entweder der Artikel oder der Kunde sind unbekannt");
            return;
        }
        let documentPositionId = order.documentPositions[index].id ? order.documentPositions[index].id : -1;
        let url = "/document-positions/load-last-price/" + documentPositionId + "/" + order.assignedCustomer.id + "/" + order.documentPositions[index].article.id;
        api.get(url)
            .then(response => {
                if(!response.data) {
                    props.showMessage(1,"Es wurde kein Preis für diesen Artikel mit diesem Kunden gefunden");
                }else{
                    let updatedOrder = { ...order };
                    updatedOrder.documentPositions[index].piecePrice = response.data;
                    setOrder(updatedOrder);
                    props.showMessage(0,"Der Preis wurde aktualisiert");
                }
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const orderPositionArticleOnChange = (article,index,newArticle) => {
        const updatedDocumentPositions = [...order.documentPositions];
        updatedDocumentPositions[index].article = article;
        if(article) {
            if(!article.articleGroup && props.company && props.company.defaultArticleGroup) {
                article.articleGroup = props.company.defaultArticleGroup;
            }
            if(article.articleGroup && article.articleGroup.vat) {
                updatedDocumentPositions[index].positionVat = article.articleGroup.vat;
            }
            if(article.piecePrice) {
                updatedDocumentPositions[index].piecePrice = article.piecePrice;
            }
            if(article.defaultDuration) {
                updatedDocumentPositions[index].duration = article.defaultDuration;
            }
        }
        if(newArticle) {
            api.post("/articles",article)
                .then(response => {
                    updatedDocumentPositions[index].article = response.data;
                    setOrder({
                        ...order,
                        documentPositions: updatedDocumentPositions
                    });
                    props.addArticleToList(response.data);
                    setIsSaved(false);
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data) {
                        props.showMessage(2,error.response.data);
                    } else if(!error.response && error.request) {
                        props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    } else {
                        props.showMessage(2,"Etwas ist schiefgelaufen");
                        props.logout();
                    }
                    props.setLoading(false);
                });
        }else{
            setOrder({
                ...order,
                documentPositions: updatedDocumentPositions
            });
            setIsSaved(false);
        }
    }

    const positionNoteOnChangeHandler = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].note = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const positionDeliveryDateOnChangeHandler = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].deliveryDate = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionArticleNrOnChangeHandler = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].article.nr = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionInventoryCountOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].inventoryCount = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionUnitOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].unit = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const orderPositionInventoryCountInvoiceOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].inventoryCountInvoice = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionInventoryCountDeliveryBillOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].inventoryCountDeliveryBill = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionPiecePriceOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].piecePrice = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const orderPositionVatOnChange = (e,i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].positionVat = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    };

    const removeOrderPosition = (index) => {
        const updatedOrder = { ...order };
        let updatedDocumentPositions = [ ...updatedOrder.documentPositions ]
        updatedDocumentPositions.splice(index,1);
        for(let i = index; i < updatedDocumentPositions.length; i++) {
            updatedDocumentPositions[i].position = i + 1;
        }
        updatedOrder.documentPositions = updatedDocumentPositions;
        setOrder(updatedOrder);
        if(updatedDocumentPositions.length > 0) {
            if(index !== 0) {
                setPositionIndex(index - 1);
            }else{
                setPositionIndex(0);
            }
        }
        setIsSaved(false);
    };

    const positionDescriptionOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].description = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const positionHintOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].hint = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const addBatch = (i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].batch = {};
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const removeBatch = (i) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[i].batch = null;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const saveOrderOnClickHandler = () => {
        if(validateOrder(order)) {
            if(order.id) {
                updateOrder(order)
            }else{
                if(order.commissionNr) {
                    addNewOrder(order);
                }else{
                    showCommissionNrDialog();
                }
            }
        }
    }

    const validateOrder = (order) => {
        if(props.kind === 0 && (!order.assignedCustomer || !order.assignedCustomer.name)) {
            props.showMessage(2,"Der Bestellung wurde kein Kunde zugewiesen");
            return false;
        }else if(props.kind === 1 && (!order.assignedSupplier || !order.assignedSupplier.name)) {
            props.showMessage(2,"Der Bestellung wurde kein Lieferant zugewiesen");
            return false;
        }
        for(let i = 0; i < order.documentPositions.length; i++) {
            if(!order.documentPositions[i].article || !order.documentPositions[i].article.name) {
                props.showMessage(2,"Mindestens einer Position wurde kein Artikel zugewiesen");
                return false;
            }else if(order.documentPositions[i].inventoryCount === '' || order.documentPositions[i].inventoryCount <= 0) {
                props.showMessage(2,"Mindestens eine Position hat eine ungültige Anzahl");
                return false;
            }else if(order.documentPositions[i].batch && !order.documentPositions[i].batch.nr) {
                props.showMessage(2,"Mindestens eine Charge hat keine Nummer");
                return false;
            }
        }
        return true;
    }

    const bookInventoryTransactionsOnClickHandler = (order) => {
        if (order.bookedTransactions) {
            showBookInventoryTransactionsWarningDialog();
        }else {
            props.bookInventoryTransactions(order);
            order.bookedTransactions = true;
            setOrder(order);
        }
    }

    const bookGoodsMovementOnClickHandler = (order) => {
        if(order.bookedGoodsMovement) {
            showBookGoodsMovementWarningDialog();
        }else{
            if(props.kind === 0) {
                bookCustomerGoodsMovement(order);
            }else if(props.kind === 1) {
                bookSupplierGoodsMovement(order);
            }
        }
    }

    const bookCustomerGoodsMovement = (order) => {
        if(order.assignedCustomer.receivablesAccount === null) {
            props.showMessage(2,'Dem Kunden ist kein Forderungskonto zugewiesen');
            return;
        }
        for(let i = 0; i < order.documentPositions.length; i++) {
            if(
                order.documentPositions[i].article.articleGroup.consumptionAccount === null ||
                order.documentPositions[i].article.articleGroup.stockAccount === null ||
                order.documentPositions[i].article.articleGroup.revenueAccount === null ||
                order.documentPositions[i].article.articleGroup.vatAccount === null
            ) {
                props.showMessage(2,'Einem der Artikel ist keine Artikelgruppe, oder der Artikelgruppe nicht alle nötigen Konten zugewiesen');
                return;
            }
        }
        let accountEntryList = [];
        for(let i = 0; i < order.documentPositions.length; i++) {

            //consumptionAccount (Verbrauchskonto)
            let consumptionAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.documentPositions[i].article.articleGroup.consumptionAccount.id === accountEntryList[j].account.id) {
                    consumptionAccountExistsIndex = j;
                    break;
                }
            }
            if(consumptionAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[consumptionAccountExistsIndex].entry.value = accountEntryList[consumptionAccountExistsIndex].entry.value + (order.documentPositions[i].article.purchaseNetPrice * order.documentPositions[i].inventoryCount);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.documentPositions[i].article.articleGroup.consumptionAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:order.documentPositions[i].article.purchaseNetPrice * order.documentPositions[i].inventoryCount
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //stockAccount (Bestandskonto)
            let stockAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.documentPositions[i].article.articleGroup.stockAccount.id === accountEntryList[j].account.id) {
                    stockAccountExistsIndex = j;
                    break;
                }
            }
            if(stockAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[stockAccountExistsIndex].entry.value = accountEntryList[stockAccountExistsIndex].entry.value - (order.documentPositions[i].article.purchaseNetPrice * order.documentPositions[i].inventoryCount);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.documentPositions[i].article.articleGroup.stockAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:-(order.documentPositions[i].article.purchaseNetPrice * order.documentPositions[i].inventoryCount)
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //receivablesAccount (Forderungskonto)
            let receivablesAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.assignedCustomer.receivablesAccount.id === accountEntryList[j].account.id) {
                    receivablesAccountExistsIndex = j;
                    break;
                }
            }
            if(receivablesAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[receivablesAccountExistsIndex].entry.value = accountEntryList[receivablesAccountExistsIndex].entry.value + (order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.assignedCustomer.receivablesAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100)))
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //revenueAccount (Erlöskonto)
            let revenueAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.documentPositions[i].article.articleGroup.revenueAccount.id === accountEntryList[j].account.id) {
                    revenueAccountExistsIndex = j;
                    break;
                }
            }
            if(revenueAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[revenueAccountExistsIndex].entry.value = accountEntryList[revenueAccountExistsIndex].entry.value - (order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.documentPositions[i].article.articleGroup.revenueAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:-(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount)
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //vatAccount (Mehrwertsteuerkonto)
            let vatAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.documentPositions[i].article.articleGroup.vatAccount.id === accountEntryList[j].account.id) {
                    vatAccountExistsIndex = j;
                    break;
                }
            }
            if(vatAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[vatAccountExistsIndex].entry.value = accountEntryList[vatAccountExistsIndex].entry.value - (order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * order.documentPositions[i].article.articleGroup.vat / 100);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.documentPositions[i].article.articleGroup.vatAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:-(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * order.documentPositions[i].article.articleGroup.vat / 100)
                    }
                }
                accountEntryList.push(accountEntry);
            }
        }
        order.bookedGoodsMovement = true;
        for(let i = 0; i < accountEntryList.length; i++) {
            accountEntryList[i].entry.value = props.round(accountEntryList[i].entry.value,2);
        }
        props.addMultipleEntries(accountEntryList,order,null);
    }

    const documentPositionStateOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].state = e.target.value;
        let uniform = true;
        let uniformState = null;
        for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
            if(i !== 0 && updatedOrder.documentPositions[i].state !== uniformState) {
                uniform = false;
                break;
            }else{
                uniformState = updatedOrder.documentPositions[i].state;
            }
            if(updatedOrder.documentPositions[i].state === "NOT_STARTED") {
                uniform = false;
                break;
            }
        }
        if(uniform) {
            props.showMessage(1,'Alle Positionen haben den gleichen Status. Denken Sie daran, den Status der Bestellung zu ändern, sofern gewünscht.');
        }
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const bookSupplierGoodsMovement = (supplierOrder) => {
        if(supplierOrder.assignedSupplier.liabilitiesAccount === null) {
            props.showMessage(2,'Dem Lieferanten ist kein Verbindlichkeitskonto zugewiesen');
            return;
        }
        for(let i = 0; i < supplierOrder.documentPositions.length; i++) {
            if(
                supplierOrder.documentPositions[i].article.articleGroup.stockAccount === null ||
                supplierOrder.documentPositions[i].article.articleGroup.inputTaxAccount === null
            ) {
                props.showMessage(2,'Einem der Artikel ist keine Artikelgruppe, oder der Artikelgruppe nicht alle nötigen Konten zugewiesen');
                return;
            }
        }
        let accountEntryList = [];
        for(let i = 0; i < supplierOrder.documentPositions.length; i++) {

            //stockAccount (Bestandskonto)
            let stockAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (supplierOrder.documentPositions[i].article.articleGroup.stockAccount.id === accountEntryList[j].account.id) {
                    stockAccountExistsIndex = j;
                    break;
                }
            }
            if(stockAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[stockAccountExistsIndex].entry.value = accountEntryList[stockAccountExistsIndex].entry.value + (supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:supplierOrder.documentPositions[i].article.articleGroup.stockAccount,
                    entry:{
                        nr:supplierOrder.orderNr,
                        invoiceNr:supplierOrder.invoiceNr,
                        invoiceDate:supplierOrder.invoiceDate,
                        value:(supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount)
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //inputTaxAccount (Vorsteuerkonto)
            let inputTaxAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (props.company.inputTaxAccount.id === accountEntryList[j].account.id) {
                    inputTaxAccountExistsIndex = j;
                    break;
                }
            }
            if(inputTaxAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[inputTaxAccountExistsIndex].entry.value = accountEntryList[inputTaxAccountExistsIndex].entry.value + (supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * supplierOrder.documentPositions[i].article.articleGroup.vat / 100);
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:props.company.inputTaxAccount,
                    entry:{
                        nr:supplierOrder.orderNr,
                        invoiceNr:supplierOrder.invoiceNr,
                        invoiceDate:supplierOrder.invoiceDate,
                        value:(supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * supplierOrder.documentPositions[i].article.articleGroup.vat / 100)
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //liabilitiesAccount (Verbindlichkeiten)
            let liabilitiesAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (supplierOrder.assignedSupplier.liabilitiesAccount.id === accountEntryList[j].account.id) {
                    liabilitiesAccountExistsIndex = j;
                    break;
                }
            }
            if(liabilitiesAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[liabilitiesAccountExistsIndex].entry.value = accountEntryList[liabilitiesAccountExistsIndex].entry.value - (supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:supplierOrder.assignedSupplier.liabilitiesAccount,
                    entry:{
                        nr:supplierOrder.orderNr,
                        invoiceNr:supplierOrder.invoiceNr,
                        invoiceDate:supplierOrder.invoiceDate,
                        value:-(supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)))
                    }
                }
                accountEntryList.push(accountEntry);
            }
        }
        supplierOrder.bookedGoodsMovement = true;
        for(let i = 0; i < accountEntryList.length; i++) {
            accountEntryList[i].entry.value = props.round(accountEntryList[i].entry.value,2);
        }
        props.addMultipleEntries(accountEntryList,null,supplierOrder);
    }

    const bookPaidInvoiceEntriesOnClickHandler = (order) => {
        if(order.bookedPaidInvoice) {
            showBookPaidInvoiceWarningDialog(order);
        }else{
            if(props.kind === 0) {
                bookCustomerOrderPaidInvoiceEntries(order);
            }else if(props.kind === 1) {
                bookSupplierOrderPaidInvoiceEntries(order);
            }
        }
    }

    const bookCustomerOrderPaidInvoiceEntries = (order) => {
        if(order.assignedCustomer.receivablesAccount === null) {
            props.showMessage(2,'Dem Kunden ist kein Forderungskonto zugewiesen');
            return;
        }
        if(props.company.bankAccount === null) {
            props.showMessage(2,'Dem Unternehmen ist kein Bankkonto zugewiesen');
            return;
        }
        let accountEntryList = [];
        for(let i = 0; i < order.documentPositions.length; i++) {

            //bankAccount (Bankkonto)
            let bankAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (props.company.bankAccount.id === accountEntryList[j].account.id) {
                    bankAccountExistsIndex = j;
                    break;
                }
            }
            if(bankAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[bankAccountExistsIndex].entry.value = accountEntryList[bankAccountExistsIndex].entry.value + (order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:props.company.bankAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100))
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //receivablesAccount (Forderungskonto)
            let receivablesAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (order.assignedCustomer.receivablesAccount.id === accountEntryList[j].account.id) {
                    receivablesAccountExistsIndex = j;
                    break;
                }
            }
            if(receivablesAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[receivablesAccountExistsIndex].entry.value = accountEntryList[receivablesAccountExistsIndex].entry.value - (order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:order.assignedCustomer.receivablesAccount,
                    entry:{
                        nr:order.orderNr,
                        invoiceNr:order.invoiceNr,
                        invoiceDate:order.invoiceDate,
                        value:-(order.documentPositions[i].piecePrice * order.documentPositions[i].inventoryCount * (1 + (order.documentPositions[i].article.articleGroup.vat / 100)))
                    }
                }
                accountEntryList.push(accountEntry);
            }
        }
        order.bookedPaidInvoice = true;
        for(let i = 0; i < accountEntryList.length; i++) {
            accountEntryList[i].entry.value = props.round(accountEntryList[i].entry.value,2);
        }
        props.addMultipleEntries(accountEntryList,order,null);
    }

    const paymentTargetOnChangeHandler = (e) => {
        if(e.target.value <= 999) {
            const updatedOrder = { ...order };
            updatedOrder.paymentTarget = e.target.value;
            setOrder(updatedOrder);
            setIsSaved(false);
        }
    }

    const bookSupplierOrderPaidInvoiceEntries = (supplierOrder) => {
        if(props.company.bankAccount === null) {
            props.showMessage(2,'Dem Unternehmen ist kein Bankkonto zugewiesen');
            return;
        }
        if(supplierOrder.assignedSupplier.liabilitiesAccount === null) {
            props.showMessage(2,'Dem Lieferanten ist kein Verbindlichkeitskonto zugewiesen');
            return;
        }
        let accountEntryList = [];
        for(let i = 0; i < supplierOrder.documentPositions.length; i++) {

            //liabilitiesAccount (Verbindlichkeiten)
            let liabilitiesAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (supplierOrder.assignedSupplier.liabilitiesAccount.id === accountEntryList[j].account.id) {
                    liabilitiesAccountExistsIndex = j;
                    break;
                }
            }
            if(liabilitiesAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[liabilitiesAccountExistsIndex].entry.value = accountEntryList[liabilitiesAccountExistsIndex].entry.value + (supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:supplierOrder.assignedSupplier.liabilitiesAccount,
                    entry:{
                        nr:supplierOrder.orderNr,
                        invoiceNr:supplierOrder.invoiceNr,
                        invoiceDate:supplierOrder.invoiceDate,
                        value:(supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)))
                    }
                }
                accountEntryList.push(accountEntry);
            }

            //bankAccount (Bankkonto)
            let bankAccountExistsIndex = -1;
            for(let j = 0; j < accountEntryList.length; j++) {
                if (props.company.bankAccount.id === accountEntryList[j].account.id) {
                    bankAccountExistsIndex = j;
                    break;
                }
            }
            if(bankAccountExistsIndex !== -1) {
                //already exists
                accountEntryList[bankAccountExistsIndex].entry.value = accountEntryList[bankAccountExistsIndex].entry.value - (supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)));
            }else{
                //make new accountEntry
                let accountEntry = {
                    account:props.company.bankAccount,
                    entry:{
                        nr:supplierOrder.orderNr,
                        invoiceNr:supplierOrder.invoiceNr,
                        invoiceDate:supplierOrder.invoiceDate,
                        value:-(supplierOrder.documentPositions[i].piecePrice * supplierOrder.documentPositions[i].inventoryCount * (1 + (supplierOrder.documentPositions[i].article.articleGroup.vat / 100)))
                    }
                }
                accountEntryList.push(accountEntry);
            }
        }
        supplierOrder.bookedPaidInvoice = true;
        for(let i = 0; i < accountEntryList.length; i++) {
            accountEntryList[i].entry.value = props.round(accountEntryList[i].entry.value,2);
        }
        props.addMultipleEntries(accountEntryList,null,supplierOrder);
    }

    const batchNrOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].batch.nr = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchMaterialOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].batch.material = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchAddFactoryCertificate = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].batch.factoryCertificate = {
            nr:'',
            adw2:false,
            originalFileStorageLocation:null,
            editedFileStorageLocation:null
        };
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchRemoveFactoryCertificate = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].batch.factoryCertificate = undefined;
        updatedOrder.documentPositions[index].batch.multipartFile = null;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchToggleFactoryCertificateADW2 = (index) => {
        const updatedOrder = { ...order };
        order.documentPositions[index].batch.factoryCertificate.adw2 = !order.documentPositions[index].batch.factoryCertificate.adw2;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchFactoryCertificateFolderStorageOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].batch.factoryCertificate.folderStorage = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const batchFactoryCertificateFileOnChangeHandler = (e,index) => {
        let file = e.target.files[0];
        if(file.size < 10000000) {
            const updatedOrder = { ...order };
            updatedOrder.documentPositions[index].batch.factoryCertificate.multipartFile = file;
            setMultipartFile(file);
        }else{
            props.showMessage(2,"Die Datei darf maximal 10MB groß sein");
        }
    }

    const toggleExpandDocuments = (e) => {
        e.stopPropagation();
        setExpandDocuments(!expandDocuments);
    }

    const toggleExpandDeliveryBills = (e) => {
        e.stopPropagation();
        setExpandDeliveryBills(!expandDeliveryBills);
    }

    const toggleExpandInvoices = (e) => {
        e.stopPropagation();
        setExpandInvoices(!expandInvoices);
    }

    const toggleExpandCancellationInvoices = (e) => {
        e.stopPropagation();
        setExpandCancellationInvoices(!expandCancellationInvoices);
    }

    const toggleExpandOffers = (e) => {
        e.stopPropagation();
        setExpandOffers(!expandOffers);
    }

    const toggleExpandOrderConfirmations = (e) => {
        e.stopPropagation();
        setExpandOrderConfirmations(!expandOrderConfirmations);
    }

    const toggleExpandOrders = (e) => {
        e.stopPropagation();
        setExpandOrders(!expandOrders);
    }

    const toggleExpandRequests = (e) => {
        e.stopPropagation();
        setExpandRequests(!expandRequests);
    }

    const toggleExpandOthers = (e) => {
        e.stopPropagation();
        setExpandOthers(!expandOthers);
    }

    const addNewOriginalFileToItem = (multipartFile) => {
        let formData = new FormData();
        formData.append("name",multipartFile.name);
        formData.append("path","/orders/" + order.orderNr);
        formData.append("fileStorageLocationKind","FACTORY_CERTIFICATE");
        formData.append("multipartFile",multipartFile);
        api.post("/factory-certificates/original/" + factoryCertificate.id,formData)
            .then(response => {
                const updatedOrder = { ...order };
                for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    if(updatedOrder.documentPositions[i].batch && updatedOrder.documentPositions[i].batch.factoryCertificate.id === factoryCertificate.id) {
                        updatedOrder.documentPositions[i].batch.factoryCertificate.originalFileStorageLocation = response.data;
                    }
                }
                setOrder(updatedOrder);
                props.showMessage(0,'Datei erfolgreich angehängt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const addNewEditedFileToItem = (multipartFile) => {
        let formData = new FormData();
        formData.append("name",multipartFile.name);
        formData.append("path","/orders/" + order.orderNr);
        formData.append("fileStorageLocationKind","FACTORY_CERTIFICATE");
        formData.append("multipartFile",multipartFile);
        api.post("/factory-certificates/edited/" + factoryCertificate.id,formData)
            .then(response => {
                const updatedOrder = { ...order };
                for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    if(updatedOrder.documentPositions[i].batch && updatedOrder.documentPositions[i].batch.factoryCertificate.id === factoryCertificate.id) {
                        updatedOrder.documentPositions[i].batch.factoryCertificate.editedFileStorageLocation = response.data;
                    }
                }
                setOrder(updatedOrder);
                props.showMessage(0,'Datei erfolgreich angehängt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const addFileToOrder = (multipartFile,localFileStorageLocationKind,generatedOrder,setOrderCallback) => {
        let kind;
        if(localFileStorageLocationKind) {
            kind = localFileStorageLocationKind;
        }else{
            kind = fileStorageLocationKind;
        }
        let formData = new FormData();
        let path = "";
        if(order && order.id && (props.kind === 0 || props.kind === 1)) {
            path = props.kind === 0 ? ("customer_order_" + order.id) : props.kind === 1 ? ("supplier_order_" + order.id) : "";
        }else{
            props.showMessage(2,"Die Bestellung muss erst gespeichert werden, bevor eine Datei angehängt werden kann");
            return;
        }
        formData.append("name",multipartFile.name);
        formData.append("path","/orders/" + path);
        formData.append("fileStorageLocationKind",kind);
        if(generatedOrder && generatedOrder.documentNr) {
            formData.append("documentNr",generatedOrder.documentNr);
        }
        if(generatedOrder && generatedOrder.documentDate) {
            formData.append("documentDate",generatedOrder.documentDate);
        }
        formData.append("multipartFile",multipartFile);
        let url = props.kind === 0 ? "/customer-orders/file/" : "/supplier-orders/file/";
        api.post(url + order.id,formData)
            .then(response => {
                const updatedOrder = { ...order };
                updatedOrder.fileStorageLocations.push(response.data);
                props.showMessage(0,'Datei erfolgreich angehängt');
                if(generatedOrder) {
                    updateGeneratedDocumentPositions(updatedOrder,generatedOrder,kind,setOrderCallback);
                }
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const setOrderWithCallback = (updatedOrder, callback) => {
        setOrder(updatedOrder);
        callback();
    };

    const deleteFile = () => {
        props.setLoading(true);
        let url = props.kind === 0 ? "/customer-orders/file/" + order.id : "/supplier-orders/file/" + order.id;
        api.put(url,fileStorageLocation)
            .then(response => {
                const updatedOrder = { ...order };
                for(let i = 0; i < updatedOrder.fileStorageLocations.length; i++) {
                    if(updatedOrder.fileStorageLocations[i].id === response.data.id) {
                        updatedOrder.fileStorageLocations.splice(i,1);
                    }
                }
                setOrder(updatedOrder);
                props.showMessage(0,'Datei erfolgreich entfernt');
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const orderDurationOnChangeHandler = (e) => {
        const updatedOrder = { ...order };
        updatedOrder.duration = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const durationOnChangeHandler = (e,index) => {
        const updatedOrder = { ...order };
        order.documentPositions[index].duration = e.target.value;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const offerPositionOnChangeHandler = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].offerPosition = !updatedOrder.documentPositions[index].offerPosition;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const guidePriceOnChangeHandler = (index) => {
        const updatedOrder = { ...order };
        updatedOrder.documentPositions[index].guidePrice = !updatedOrder.documentPositions[index].guidePrice;
        setOrder(updatedOrder);
        setIsSaved(false);
    }

    const addFileStorageLocationToList = (fileStorageLocation) => {
        const updatedFileStorageLocations = fileStorageLocations;
        updatedFileStorageLocations.push(fileStorageLocation);
        setFileStorageLocations(updatedFileStorageLocations);
    }

    const displayFile = (path,fileStorageLocation) => {
        let pathFileRequest = {
            path:path,
            fileStorageLocation:fileStorageLocation
        }
        props.displayFile(pathFileRequest);
    }

    const addEmailAttachment = (fileStorageLocation,sendWarning) => {
        fileStorageLocation.sendWarning = sendWarning;
        props.addEmailAttachment(fileStorageLocation);
    }

    const removeOriginalFileFromFactoryCertificate = (index) => {
        let urlRoot = props.kind === 0 ? "/customer-orders" : "/supplier-orders";
        api.put(urlRoot + "/remove/original-factory-certificate",order.documentPositions[index].batch.factoryCertificate)
            .then(response => {
                const updatedOrder = { ...order };
                updatedOrder.documentPositions[index].batch.factoryCertificate = response.data;
                setOrder(updatedOrder);
                props.showMessage(0,'Datei erfolgreich entfernt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const removeEditedFileFromFactoryCertificate = (index) => {
        let urlRoot = props.kind === 0 ? "/customer-orders" : "/supplier-orders";
        api.put(urlRoot + "/remove/edited-factory-certificate",order.documentPositions[index].batch.factoryCertificate)
            .then(response => {
                const updatedOrder = { ...order };
                updatedOrder.documentPositions[index].batch.factoryCertificate = response.data;
                setOrder(updatedOrder);
                props.showMessage(0,'Datei erfolgreich entfernt');
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const sortedDocumentPositions = (documentPositions) => {
        return documentPositions.sort((a,b) => a.position - b.position);
    }

    const moveDocumentPositionUp = (index) => {
        if (index <= 0 || index >= order.documentPositions.length) {
            return;
        }
        const updatedDocumentPositions = [...order.documentPositions];
        updatedDocumentPositions[index].position = updatedDocumentPositions[index].position - 1;
        updatedDocumentPositions[index - 1].position = updatedDocumentPositions[index - 1].position + 1;
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedDocumentPositions)
        });
        setIsSaved(false);
    }

    const moveDocumentPositionDown = (index) => {
        if (index < 0 || index >= order.documentPositions.length - 1) {
            return;
        }
        const updatedDocumentPositions = [...order.documentPositions];
        updatedDocumentPositions[index].position = updatedDocumentPositions[index].position + 1;
        updatedDocumentPositions[index + 1].position = updatedDocumentPositions[index + 1].position - 1;
        setOrder({
            ...order,
            documentPositions: sortedDocumentPositions(updatedDocumentPositions)
        });
        setIsSaved(false);
    }

    const copyOrder = () => {
        let newOrder = window.structuredClone(order);
        newOrder.originalOrderId = order.id;
        newOrder.id = null;
        newOrder.orderNr = null;
        newOrder.invoiceNr = null;
        newOrder.commissionNr = null;
        newOrder.invoiceDate = null;
        newOrder.deliveryDate = null;
        newOrder.orderDate = null;
        newOrder.locked = null;
        newOrder.fileStorageLocations = [];
        newOrder.customerOrders = [];
        newOrder.supplierOrders = [];
        for(let i = 0; i < newOrder.documentPositions.length; i++) {
            newOrder.documentPositions[i].frontendId = HelperFunctions.generateRandomString(6);
            newOrder.documentPositions[i].id = null;
            newOrder.documentPositions[i].deliveryDate = null;
            newOrder.documentPositions[i].inventoryCountInvoice = 0;
            newOrder.documentPositions[i].inventoryCountDeliveryBill = 0;
            if(newOrder.documentPositions[i].batch) {
                newOrder.documentPositions[i].batch.id = null;
                if(newOrder.documentPositions[i].batch.factoryCertificate) {
                    newOrder.documentPositions[i].batch.factoryCertificate.id = null;
                    if(newOrder.documentPositions[i].batch.factoryCertificate.fileStorageLocation) {
                        newOrder.documentPositions[i].batch.factoryCertificate.fileStorageLocation = null;
                    }
                }
            }
        }
        setIsSaved(false);
        setOrder(newOrder);
        props.setActiveOrder(newOrder);
        props.showMessage(0,"Die Bestellung wurde kopiert! Sie befinden sich jetzt in der kopierten Bestellung");
    }

    const connectedOrdersSearchWordOnChangeHandler = (e) => {
        setConnectedOrdersSearchWord(e.target.value);
    }

    const connectedOrdersSearchOnClickHandler = () => {
        if(connectedOrdersSearchType && connectedOrdersSearchWord) {
            setConnectedOrders([]);
            setConnectedLoadingSearch(true);
            loadConnectedOrders();
        }else{
            props.showMessage(2,'Bitte wählen Sie zunächst eine Suche und ein Suchwort aus');
        }
    }

    const setConnectedLoadingSearch = (loadingState) => {
        setConnectedLoading(loadingState);
    }

    const loadConnectedOrders = () => {
        let url;
        switch (props.kind) {
            case 0:
                url = '/supplier-orders';
                break;
            case 1:
                url = '/customer-orders';
                break;
            default:
                break;
        }
        url = url + "/search/" + connectedOrdersSearchType + "/" + connectedOrdersSearchWord;
        let filter = {filter:null};
        api.post(url,filter)
            .then(response => {
                if(response.data.length > 0) {
                    setConnectedOrders(response.data);
                }else{
                    props.showMessage(1,"Es wurden keine Bestellungen gefunden");
                }
                setConnectedLoadingSearch(false);
            })
            .catch(error => {
                props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
                setConnectedLoadingSearch(false);
                props.logout();
            });
    }

    const connectedOrdersSearchTypeOnChangeHandler = (e) => {
        setConnectedOrdersSearchType(e.target.value);
    }

    const addConnectedOrder = (connectOrder) => {
        let url;
        switch (props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        url = url + "/connect/" + order.id + "/" + connectOrder.id;
        let filter = {filter:null};
        api.put(url,filter)
            .then(response => {
                setOrder(response.data);
                setConnectedLoadingSearch(false);
            })
            .catch(error => {
                props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
                setConnectedLoadingSearch(false);
            });
    }

    const removeConnectedOrder = (connectOrder) => {
        let url;
        switch (props.kind) {
            case 0:
                url = '/customer-orders';
                break;
            case 1:
                url = '/supplier-orders';
                break;
            default:
                break;
        }
        url = url + "/disconnect/" + order.id + "/" + connectOrder.id;
        let filter = {filter:null};
        api.put(url,filter)
            .then(response => {
                setOrder(response.data);
                setConnectedLoadingSearch(false);
            })
            .catch(error => {
                props.showMessage(2,"Etwas ist schief gelaufen!");
                console.log(error)
                setConnectedLoadingSearch(false);
                props.logout();
            });
    }

    //Dialog actions

    const showBookGoodsMovementWarningDialog = () => {
        setBookGoodsMovementWarningDialog(true);
    }

    const closeBookGoodsMovementWarningDialog = () => {
        setBookGoodsMovementWarningDialog(false);
    }

    const showBookPaidInvoiceWarningDialog = () => {
        setBookPaidInvoiceWarningDialog(true);
    }

    const closeBookPaidInvoiceWarningDialog = () => {
        setBookPaidInvoiceWarningDialog(false);
    }

    const showBookInventoryTransactionsWarningDialog = () => {
        setBookInventoryTransactionsWarningDialog(true);
    }

    const closeBookInventoryTransactionsWarningDialog = () => {
        setBookInventoryTransactionsWarningDialog(false);
    }

    const showGenerateDeliveryBillWarningDialog = () => {
        setGenerateDeliveryBillWarningDialog(true);
    }

    const closeGenerateDeliveryBillWarningDialog = () => {
        setGenerateDeliveryBillWarningDialog(false);
    }

    const showBookGoodsMovementEntriesWarningDialog = () => {
        setBookGoodsMovementEntriesWarningDialog(true);
    }

    const closeBookGoodsMovementEntriesWarningDialog = () => {
        setBookGoodsMovementEntriesWarningDialog(false);
    }

    const showBookPaidInvoiceEntriesWarningDialog = () => {
        setBookPaidInvoiceEntriesWarningDialog(true);
    }

    const closeBookPaidInvoiceEntriesWarningDialog = () => {
        setBookPaidInvoiceEntriesWarningDialog(false);
    }

    const showSendMailDialog = (fileStorageLocation) => {
        setFileStorageLocations([fileStorageLocation]);
        setSendMailDialog(true);
    }

    const closeSendMailDialog = () => {
        setSendMailDialog(false);
    }

    const showOriginalFileDialog = (factoryCertificate) => {
        setFactoryCertificate(factoryCertificate);
        setOriginalFileDialog(true);
    }

    const closeOriginalFileDialog = () => {
        setOriginalFileDialog(false);
    }

    const showEditedFileDialog = (factoryCertificate) => {
        setFactoryCertificate(factoryCertificate);
        setEditedFileDialog(true);
    }

    const closeEditedFileDialog = () => {
        setEditedFileDialog(false);
    }

    const showFileDialog = (fileStorageLocationKind) => {
        setFileStorageLocationKind(fileStorageLocationKind);
        setFileDialog(true);
    }

    const closeFileDialog = () => {
        setFileDialog(false);
    }

    const showDeleteFileDialog = (fileStorageLocation) => {
        setFileStorageLocation(fileStorageLocation);
        setDeleteFileDialog(true);
    }

    const closeDeleteFileDialog = () => {
        setDeleteFileDialog(false);
    }

    const showDeleteOriginalFileDialog = (index) => {
        setIndex(index);
        setDeleteOriginalFileDialog(true);
    }

    const closeDeleteOriginalFileDialog = () => {
        setDeleteOriginalFileDialog(false);
    }

    const showDeleteEditedFileDialog = (index) => {
        setIndex(index);
        setDeleteEditedFileDialog(true);
    }

    const closeDeleteEditedFileDialog = () => {
        setDeleteEditedFileDialog(false);
    }

    const showGenerateInvoiceWarningDialog = () => {
        setGenerateDeliveryBillWarningDialog(true);
    }

    const closeGenerateInvoiceWarningDialog = () => {
        setGenerateDeliveryBillWarningDialog(false);
    }

    const showCopyOrderDialog = () => {
        setCopyOrderDialog(true);
    }

    const closeCopyOrderDialog = () => {
        setCopyOrderDialog(false);
    }

    const showMessageTemplatesDialog = (mailInformation) => {
        setMailInformation(mailInformation);
        setMessageTemplatesDialog(true);
    }

    const closeMessageTemplatesDialog = () => {
        setMailAdresses(null);
        setMailInformation(null);
        setMessageTemplatesDialog(false);
    }

    const showPDFDialog = (fileStorageLocation) => {
        props.setLoading(true);
        const url = '/file-storage';
        api.post(url,fileStorageLocation,{responseType:'blob'})
            .then(response => {
                const pdfFile = new Blob(
                    [response.data],
                    {type: response.data.type});
                pdfFile.name = fileStorageLocation.name;
                const pdfFileURL = URL.createObjectURL(pdfFile);
                setFileStorageLocation(fileStorageLocation);
                setPdfFile(pdfFile);
                setPdfFileURL(pdfFileURL);
                setPDFDialog(true);
                props.setLoading(false);
            })
            .catch(error => {
                props.showMessage(2,"Etwas ist schiefgelaufen");
                console.log(error);
                props.setLoading(false);
            });
    }

    const attachPdf = async (newPdfFile) => {
        setPDFDialog(false);
        try {
            // Load the existing PDF (from server) into a PDFDocument
            const existingPdfBytes = await pdfFile.arrayBuffer();
            const existingPdfDoc = await PDFDocument.load(existingPdfBytes);

            // Load the new PDF (from file input) into a PDFDocument
            const newPdfBytes = await newPdfFile.arrayBuffer();
            const newPdfDoc = await PDFDocument.load(newPdfBytes);

            // Get the number of pages in both PDFs
            const existingPdfPageCount = existingPdfDoc.getPageCount();
            const newPdfPageCount = newPdfDoc.getPageCount();

            // Copy all pages from the new PDF into the existing PDF
            for (let i = 0; i < newPdfPageCount; i++) {
                const [newPage] = await existingPdfDoc.copyPages(newPdfDoc, [i]);
                existingPdfDoc.addPage(newPage);
            }

            // Serialize the combined PDF back to bytes
            const combinedPdfBytes = await existingPdfDoc.save();

            // Convert the combined bytes back into a Blob
            const combinedPdfBlob = new Blob([combinedPdfBytes], { type: 'application/pdf' });

            // Create a URL for the new combined PDF
            const combinedPdfURL = URL.createObjectURL(combinedPdfBlob);

            // You can now display the combined PDF, download it, or store it as needed
            setPdfFile(combinedPdfBlob);
            setPdfFileURL(combinedPdfURL);
            setPDFDialog(true);
        } catch (error) {
            console.error("Error combining PDFs:", error);
        }
    };

    const updatePdf = () => {
        props.setLoading(true);
        let formData = new FormData();
        formData.append("multipartFile",pdfFile);
        api.put("/file-storage/" + fileStorageLocation.id,formData)
            .then(response => {
                props.showMessage(0,"Die Änderungen wurden erfolgreich gespeichert");
                closePDFDialog();
                props.setLoading(false);
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                    props.logout();
                }
                props.setLoading(false);
            });
    }

    const closePDFDialog = () => {
        setFileURL(null);
        setPDFDialog(false);
    }

    const showCommissionNrDialog = () => {
        setCommissionNrDialog(true);
    }

    const closeCommissionNrDialog = () => {
        setCommissionNrDialog(false);
    }

    const invoiceDateAnticipatedOnChangeHandler = () => {
        const updatedOrder = { ...order };
        updatedOrder.invoiceDateAnticipated = !updatedOrder.invoiceDateAnticipated;
        setOrder(updatedOrder);
    }

    const deliveryDateAnticipatedOnChangeHandler = () => {
        const updatedOrder = { ...order };
        updatedOrder.deliveryDateAnticipated = !updatedOrder.deliveryDateAnticipated;
        setOrder(updatedOrder);
    }

    const togglePositionDisplay = () => {
        if(order.documentPositions && order.documentPositions.length > 0)
            setPositionIndex(0);
        setPositionDisplay(!positionDisplay);
    }

    const loadOriginalOrder = () => {
        let url =props.kind === 0 ? "/customer-orders" : "/supplier-orders";
        api.post(url + "/" + order.originalOrderId)
            .then(response => {
                setOrder(response.data);
                props.showMessage(0,"Sie befinden nun in der Origianlbestellung");
            })
            .catch(error => {
                console.log(error);
                if(error.response && error.response.data) {
                    props.showMessage(2,error.response.data);
                } else if(!error.response && error.request) {
                    props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                } else {
                    props.showMessage(2,"Etwas ist schiefgelaufen");
                }
                props.setLoading(false);
            });
    }

    const updateGeneratedDocumentPositions = (updatedOrder,generatedOrder,kind,setOrderCallback) => {
        if(kind === "INVOICE" || kind === "DELIVERY_BILL") {
            for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
                for(let j = 0; j < generatedOrder.documentPositions.length; j++) {
                    if(updatedOrder.documentPositions[i].id === generatedOrder.documentPositions[j].id) {
                        if(kind === "INVOICE") {
                            let currentValue = updatedOrder.documentPositions[i].inventoryCountInvoice ? parseFloat(updatedOrder.documentPositions[i].inventoryCountInvoice) : 0;
                            let addedValue = generatedOrder.documentPositions[j].inventoryCount ? parseFloat(generatedOrder.documentPositions[j].inventoryCount) : 0;
                            updatedOrder.documentPositions[i].inventoryCountInvoice = currentValue + addedValue;
                            generatedOrder.documentPositions[j].inventoryCountInvoice = currentValue + addedValue;
                            if(updatedOrder.documentPositions[i].state !== "COMPLETED" && updatedOrder.documentPositions[i].inventoryCountInvoice >= updatedOrder.documentPositions[i].inventoryCount) {
                                updatedOrder.documentPositions[i].state = "COMPLETED";
                            }
                        }else if(kind === "DELIVERY_BILL") {
                            let currentValue = updatedOrder.documentPositions[i].inventoryCountDeliveryBill ? parseFloat(updatedOrder.documentPositions[i].inventoryCountDeliveryBill) : 0;
                            let addedValue = generatedOrder.documentPositions[j].inventoryCount ? parseFloat(generatedOrder.documentPositions[j].inventoryCount) : 0;
                            updatedOrder.documentPositions[i].inventoryCountDeliveryBill = currentValue + addedValue;
                            generatedOrder.documentPositions[j].inventoryCountDeliveryBill = currentValue + addedValue;
                        }
                        break;
                    }
                }
            }
            if(updatedOrder.orderState !== "NO_STATE" && updatedOrder.orderState !== "COMPLETED") {
                let completed = true;
                for(let i = 0; i < updatedOrder.documentPositions.length; i++) {
                    if(updatedOrder.documentPositions[i].state !== "COMPLETED") {
                        completed = false;
                        break;
                    }
                }
                if(completed) {
                    updatedOrder.orderState = "COMPLETED";
                }
            }
            let url = props.kind === 0 ? "/customer-orders" : "/supplier-orders";
            api.put(url,updatedOrder)
                .then(response => {
                    setOrder(response.data);
                    if(setOrderCallback) {
                        setOrderCallback(generatedOrder);
                    }
                })
                .catch(error => {
                    console.log(error);
                    if(error.response && error.response.data) {
                        props.showMessage(2,error.response.data);
                    } else if(!error.response && error.request) {
                        props.showMessage(2,"Es kann keine Verbindung zum Server aufgebaut werden");
                    } else {
                        props.showMessage(2,"Etwas ist schiefgelaufen");
                    }
                    props.setLoading(false);
                });
        }
    }

    const showConnectedOrdersDialog = () => {
        setConnectedOrdersDialog(true);
    }

    const closeConnectedOrdersDialog = () => {
        setConnectedOrdersDialog(false);
    }

    const showGenerateDocumentDialog = () => {
        setGenerateDocumentDialog(true);
    }

    const closeGenerateDocumentDialog = () => {
        setGenerateDocumentDialog(false);
    }

    const showMultipleMailAdressesContextMenu = (e,mailAdresses) => {
        e.preventDefault();
        let anchorPoint = {
            x:e.clientX,
            y:e.clientY
        }
        setAnchorPoint(anchorPoint);
        setMailAdresses(mailAdresses);
        setMultipleMailAdressesContextMenu(true);
    }

    const closeMultipleMailAdressesContextMenu = () => {
        setAnchorPoint({x:0,y:0});
        setMultipleMailAdressesContextMenu(false);
    }

    const mailAdressOnClickHandler = (mailAdress) => {
        let updatedMailInformation;
        if(mailInformation) {
            updatedMailInformation = { ...mailInformation };
        }else{
            updatedMailInformation = {};
        }
        updatedMailInformation.mailAdress = mailAdress;
        showMessageTemplatesDialog(updatedMailInformation);
        closeMultipleMailAdressesContextMenu();
    }

    const close = () => {
        if(!isSaved) {
            showUnsavedChangesWarningDialog();
        }else{
            props.close();
        }
    }

    const showUnsavedChangesWarningDialog = () => {
        setUnsavedChangesWarningDialog(true);
    }

    const closeUnsavedChangesWarningDialog = () => {
        setUnsavedChangesWarningDialog(false);
    }

    const filter = createFilterOptions();

    let disabledStyle;

    if(locked) {
        disabledStyle = {
            color:"gray"
        }
    }

    let positionDisplayText;

    if(positionDisplay) {
        positionDisplayText = "Auflistungsansicht";
    }else{
        positionDisplayText = "Detailanischt";
    }

    return (
        <Dialog scroll='body' maxWidth={"xl"} open={props.open} onClose={close}
                className='order-dialog'>

            <Tooltip id="order-dialog-tooltip"/>

            <MessageTemplatesDialog
                open={messageTemplatesDialog}
                close={closeMessageTemplatesDialog}
                showMessage={props.showMessage}
                messageTemplates={props.messageTemplates}
                mailInformation={mailInformation}
                mailTo={props.mailTo}
            />

            <ConnectedOrdersDialog
                open={connectedOrdersDialog}
                close={closeConnectedOrdersDialog}
                showMessage={props.showMessage}
                connectedOrdersSearchWordOnChangeHandler={connectedOrdersSearchWordOnChangeHandler}
                connectedOrdersSearchWord={connectedOrdersSearchWord}
                connectedOrdersSearchOnClickHandler={connectedOrdersSearchOnClickHandler}
                connectedOrdersSearchType={connectedOrdersSearchType}
                connectedOrdersSearchTypeOnChangeHandler={connectedOrdersSearchTypeOnChangeHandler}
                connectedOrders={connectedOrders}
                connectedLoading={connectedLoading}
                addConnectedOrder={addConnectedOrder}
                removeConnectedOrder={removeConnectedOrder}
                order={order}
                kind={props.kind}
                user={props.user}
                formatDate={props.formatDate}
            />

            {generateDocumentDialog ? <GenerateDocumentDialog
                open={generateDocumentDialog}
                close={closeGenerateDocumentDialog}
                order={order}
                kind={props.kind}
                showMessage={props.showMessage}
                company={props.company}
                addFileToOrder={addFileToOrder}
                positionDisplay={positionDisplay}
                togglePositionDisplay={togglePositionDisplay}
                setLoading={props.setLoading}
                countries={props.countries}
            /> : null}

            {pdfDialog ? <PDFDialog
                open={pdfDialog}
                close={closePDFDialog}
                showMessage={props.showMessage}
                pdfFile={pdfFile}
                pdfFileURL={pdfFileURL}
                attachPdf={attachPdf}
                updatePdf={updatePdf}
            /> : null}

            <FileDialog
                open={originalFileDialog}
                close={closeOriginalFileDialog}
                showMessage={props.showMessage}
                uploadFile={addNewOriginalFileToItem}
                type={1}
            />

            <FileDialog
                open={editedFileDialog}
                close={closeEditedFileDialog}
                showMessage={props.showMessage}
                item={factoryCertificate}
                uploadFile={addNewEditedFileToItem}
                type={1}
            />

            <FileDialog
                open={fileDialog}
                close={closeFileDialog}
                showMessage={props.showMessage}
                uploadFile={addFileToOrder}
                type={1}
            />

            <YesCancelDialog
                open={copyOrderDialog}
                close={closeCopyOrderDialog}
                header='Bestellung kopieren'
                text='Wollen Sie die Bestellung kopieren? Nicht gespeicherte Änderungen der aktuellen Bestellung gehen verloren.'
                onClick={copyOrder}
            />

            <YesCancelDialog
                open={deleteOriginalFileDialog}
                close={closeDeleteOriginalFileDialog}
                header='Datei löschen'
                text='Wollen Sie die Datei wirklich löschen? Die Datei wird danach entgültig entfernt.'
                onClick={() => removeOriginalFileFromFactoryCertificate(index)}
            />

            <YesCancelDialog
                open={generateDeliveryBillWarningDialog}
                close={closeGenerateDeliveryBillWarningDialog}
                header='Lieferschein erneut generieren?'
                text='Es wurde bereits ein Lieferschein für diese Bestellung generiert. Möchten Sie dennoch fortfahren?'
                onClick={() => props.generateDeliveryBill(order, "deliveryBill")}
            />

            <YesCancelDialog
                open={deleteEditedFileDialog}
                close={closeDeleteEditedFileDialog}
                header='Datei löschen'
                text='Wollen Sie die Datei wirklich löschen? Die Datei wird danach entgültig entfernt.'
                onClick={() => removeEditedFileFromFactoryCertificate(index)}
            />

            <YesCancelDialog
                open={bookGoodsMovementWarningDialog}
                close={closeBookGoodsMovementWarningDialog}
                header='Einträge erneut verbuchen?'
                text='Es wurden bereits Einträge für diese Bestellung generiert. Möchten Sie dennoch fortfahren?'
                onClick={props.kind === 0 ? () => bookCustomerGoodsMovement(order) : () => bookSupplierGoodsMovement(order)}
            />

            <YesCancelDialog
                open={bookPaidInvoiceWarningDialog}
                close={closeBookPaidInvoiceWarningDialog}
                header='Einträge erneut verbuchen?'
                text='Es wurden bereits Einträge für diese Bestellung generiert. Möchten Sie dennoch fortfahren?'
                onClick={props.kind === 0 ? () => bookCustomerOrderPaidInvoiceEntries(order) : () => bookSupplierOrderPaidInvoiceEntries(order)}
            />

            <YesCancelDialog
                open={generateInvoiceWarningDialog}
                close={closeGenerateInvoiceWarningDialog}
                header='Rechnung erneut generieren?'
                text='Es wurde bereits eine Rechnung für diese Bestellung generiert. Möchten Sie dennoch fortfahren?'
                onClick={() => props.generateDocument(order, "invoice")}
            />

            <YesCancelDialog
                open={deleteFileDialog}
                close={closeDeleteFileDialog}
                header='Datei löschen'
                text='Wollen Sie die Datei wirklich löschen? Die Datei wird danach entgültig entfernt.'
                onClick={deleteFile}
            />

            <YesCancelDialog
                open={bookInventoryTransactionsWarningDialog}
                close={closeBookInventoryTransactionsWarningDialog}
                header='Transaktionen verbuchen'
                text='Es wurden bereits Transaktionen (Inventar) für diese Bestellung verbucht. Wollen Sie dennoch fortfahren?'
                onClick={() => props.bookInventoryTransactions(order)}
            />

            <YesCancelDialog
                open={commissionNrDialog}
                close={closeCommissionNrDialog}
                header='Kommissionsnummer'
                text={props.kind === 0 ? "Da keine Kommissionsnummer eingetragen wurde, wird diese automatisch vergeben. Wollen Sie fortfahren?" : "Da keine Bestellnummer eingetragen wurde, wird diese automatisch vergeben. Wollen Sie fortfahren?"}
                onClick={() => addNewOrder(order)}
            />

            <YesCancelDialog
                open={unsavedChangesWarningDialog}
                close={closeUnsavedChangesWarningDialog}
                header='Verwerfen'
                text='Ungespeicherte Änderungen gehen verloren. Wollen Sie fortfahren?'
                onClick={() => props.close()}
            />

            <div className='order-dialog-surrounder'>
                <Paper elevation={3} className="quick-actions">
                    <h2>Aktionsübersicht</h2>
                    {order && order.id ?
                        <div>
                            {order.originalOrderId ? <MoveUpIcon onClick={() => loadOriginalOrder()}
                                                                 className="icon order-icon"/> : null}
                            <ContentCopyIcon onClick={() => showCopyOrderDialog()} className="icon copy-icon"/>
                            <CompareArrowsIcon onClick={() => showConnectedOrdersDialog()}
                                               className="icon copy-icon"/>
                            {isSaved ?
                                <BusinessIcon onClick={() => bookInventoryTransactionsOnClickHandler(order)}
                                              className='icon'/> :
                                <BusinessIcon className='icon inactive'/>}
                            {isSaved ?
                                <DescriptionIcon onClick={showGenerateDocumentDialog}
                                                 className='icon'/> : <DescriptionIcon  className='icon inactive'/>}
                        </div> :
                        <p className="inactive-info">Die Bestellung muss zunächst gespeichert werden, um Aktionen durchführen zu
                            können</p>}
                    <ToolButton main disabled={locked} className='button save-button'
                                onClick={saveOrderOnClickHandler}>Speichern</ToolButton>
                </Paper>
                {multipleMailAdressesContextMenu ? <MultipleMailAdressesContextMenu
                    anchorPoint={anchorPoint}
                    mailAdressOnClickHandler={mailAdressOnClickHandler}
                    mailAdresses={mailAdresses}
                /> : null}

                <CloseIcon onClick={props.close} className='icon close-icon'/>
                {props.kind === 0 && props.company && props.company.companySettings && props.company.companySettings.executionManagement ?
                    <TextField disabled={locked} size="small"
                               value={order ? order.orderState : null}
                               onChange={orderStateOnChangeHandler} select className='field status-field'
                               label='Status'>
                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                        <MenuItem key={1} value="OFFER_CREATION">Angebotserstellung</MenuItem>
                        <MenuItem key={2} value="NOT_YET_APPROVED">Noch nicht freigegeben</MenuItem>
                        <MenuItem key={3} value="WORK_IN_PROGRESS">In Bearbeitung</MenuItem>
                        <MenuItem key={4} value="COMPLETED">Abgeschlossen</MenuItem>
                    </TextField> : null}
                {props.kind === 1 && props.company && props.company.companySettings && props.company.companySettings.executionManagement ?
                    <TextField disabled={locked} size="small"
                               value={order ? order.orderState : null}
                               onChange={orderStateOnChangeHandler} select className='field status-field'
                               label='Status'>
                        <MenuItem key={0} value="NO_STATE">Kein Status</MenuItem>
                        <MenuItem key={1} value="OFFER_REQUESTED">Angebot Angefragt</MenuItem>
                        <MenuItem key={2} value="REQUESTED">Bestellung Angefragt</MenuItem>
                        <MenuItem key={3} value="CONFIRMED">Bestätigt</MenuItem>
                        <MenuItem key={4} value="REJECTED">Abgelehnt</MenuItem>
                        <MenuItem key={5} value="GOODS_RECEIVED">Ware erhalten</MenuItem>
                    </TextField> : null}
                <DialogTitle>{props.kind === 0 ? "Kundenbestellung" : "Lieferantenbestellung"} {order && order.id ?
                    <div>
                        {order.originalOrderId ? <MoveUpIcon onClick={() => loadOriginalOrder()} data-tooltip-id="order-dialog-tooltip"
                                    data-tooltip-place="top" data-tooltip-content="Zur Originalbestellung"
                                    className="icon big-icon order-icon"/> : null}
                        <ContentCopyIcon onClick={() => showCopyOrderDialog()} data-tooltip-id="order-dialog-tooltip"
                                         data-tooltip-place="top" data-tooltip-content="Bestellung kopieren"
                                         className="icon copy-icon"/>
                        <CompareArrowsIcon onClick={() => showConnectedOrdersDialog()}
                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                           data-tooltip-content={props.kind === 0 ? "Verbundene Lieferantenbestellungen anzeigen" : "Verbundene Kundenbestellungen anzeigen"}
                                           className="icon copy-icon"/>
                        {isSaved ?
                        <BusinessIcon onClick={() => bookInventoryTransactionsOnClickHandler(order)}
                                      data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                      data-tooltip-content='Transaktionen (Inventar) verbuchen' className='icon'/> :
                            <BusinessIcon data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                          data-tooltip-content='Transaktionen (Inventar) verbuchen'
                                          className='icon inactive'/>}
                        {isSaved ?
                        <DescriptionIcon onClick={showGenerateDocumentDialog}
                                         data-tooltip-id="order-dialog-tooltip"
                                         data-tooltip-place="top"
                                         data-tooltip-content='Dokument generieren'
                                         className='icon'/> : <DescriptionIcon data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                                               data-tooltip-content='Dokument generieren'
                                                                               className='icon inactive'/>}
                    </div> :
                    <p className="inactive-info">Die Bestellung muss zunächst gespeichert werden, um Aktionen durchführen zu
                        können</p>}
                </DialogTitle>
                <div className="meta-information-holder">
                    {order && order.id ?
                        <div>
                            <Paper elevation={3} className="document-category" onClick={toggleExpandDocuments}>
                                <div className="header-content">
                                    {expandDocuments ?
                                        <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                        <ExpandMoreIcon className="icon toggle-icon"/>}
                                    <h3>Dokumente{order && order.fileStorageLocations && order.fileStorageLocations.length > 0 ? " (" + order.fileStorageLocations.length + ")" : null}</h3>
                                </div>
                                {expandDocuments ?
                                    <div>
                                        {props.kind === 0 ? <Paper className="document-category" elevation={3} onClick={toggleExpandDeliveryBills}>
                                            <div className="header-content">
                                                {expandDeliveryBills ?
                                                    <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Lieferscheine{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "DELIVERY_BILL").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "DELIVERY_BILL").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandDeliveryBills ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "DELIVERY_BILL").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("DELIVERY_BILL") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Lieferschein anhängen"
                                                           className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 0 ? <Paper className="document-category" elevation={3} onClick={toggleExpandInvoices}>
                                            <div className="header-content">
                                                {expandInvoices ? <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Rechnungen{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "INVOICE").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "INVOICE").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandInvoices ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "INVOICE").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("INVOICE") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Rechnung anhängen" className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 0 ? <Paper className="document-category" elevation={3} onClick={toggleExpandCancellationInvoices}>
                                            <div className="header-content">
                                                {expandCancellationInvoices ?
                                                    <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Stornorechnungen{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "CANCELLATION_INVOICE").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "CANCELLATION_INVOICE").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandCancellationInvoices ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "CANCELLATION_INVOICE").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("CANCELLATION_INVOICE") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Stornorechnung anhängen"
                                                           className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 0 ? <Paper className="document-category" elevation={3} onClick={toggleExpandOrderConfirmations}>
                                            <div className="header-content">
                                                {expandOrderConfirmations ?
                                                    <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Auftragsbestätigungen{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER_CONFIRMATION").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER_CONFIRMATION").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandOrderConfirmations ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER_CONFIRMATION").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    )) : null}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("ORDER_CONFIRMATION") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Auftragsbestätigung anhängen"
                                                           className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 0 ? <Paper className="document-category" elevation={3} onClick={toggleExpandOffers}>
                                            <div className="header-content">
                                                {expandOffers ? <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Angebote{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OFFER").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OFFER").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandOffers ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OFFER").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("OFFER") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Angebot anhängen" className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 1 ? <Paper className="document-category" elevation={3} onClick={toggleExpandRequests}>
                                            <div className="header-content">
                                                {expandRequests ? <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Anfragen{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "REQUEST").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "REQUEST").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandRequests ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "REQUEST").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("REQUEST") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Anfrage anhängen" className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        {props.kind === 1 ? <Paper className="document-category" elevation={3} onClick={toggleExpandOrders}>
                                            <div className="header-content">
                                                {expandOrders ?
                                                    <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Bestellungen{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandOrders ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "ORDER").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    )) : null}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("ORDER") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Bestellung anhängen"
                                                           className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper> : null}
                                        <Paper className="document-category" elevation={3} onClick={toggleExpandOthers}>
                                            <div className="header-content">
                                                {expandOthers ? <KeyboardArrowLeftIcon className="icon toggle-icon"/> :
                                                    <ExpandMoreIcon className="icon toggle-icon"/>}
                                                <h3>Sonstige
                                                    Dokumente{order && order.fileStorageLocations ? order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OTHER").length > 0 ? " (" + order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OTHER").length + ")" : "" : null}</h3>
                                            </div>
                                            {expandOthers ?
                                                <div className="toggle-content">
                                                    {order && order.fileStorageLocations && order.fileStorageLocations.filter(fileStorageLocation => fileStorageLocation.kind === "OTHER").sort((a, b) => {
                                                        if (a.uploadDateTime === null) return 1;
                                                        if (b.uploadDateTime === null) return -1;
                                                        return a.uploadDateTime.localeCompare(b.uploadDateTime);
                                                    }).map(fileStorageLocation => (
                                                        <FileItem
                                                            key={fileStorageLocation.id}
                                                            locked={locked}
                                                            fileStorageLocation={fileStorageLocation}
                                                            displayFile={() => showPDFDialog(fileStorageLocation)}
                                                            sendFileAsEmail={() => addEmailAttachment(fileStorageLocation)}
                                                            deleteFile={() => showDeleteFileDialog(fileStorageLocation)}
                                                            openInOutlookOnClickHandler={(e) => openInOutlookOnClickHandler(e, fileStorageLocation)}
                                                            downloadFile={props.downloadFile}
                                                        />
                                                    ))}
                                                    <Paper elevation={3}
                                                           onClick={!locked ? () => showFileDialog("OTHER") : null}
                                                           data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                                           data-tooltip-content="Sonstiges Dokument anhängen"
                                                           className='add-file-item'>
                                                        <AddIcon style={disabledStyle} className='icon add-file-icon'/>
                                                    </Paper>
                                                </div> : null}
                                        </Paper>
                                    </div> : null}
                            </Paper>
                        </div> : null}
                    <TextField disabled={locked} size="small"
                               value={order ? order.note : ''} multiline maxRows={5}
                               InputLabelProps={{shrink: true}}
                               label='Bemerkung (maximal 255 Zeichen)' inputProps={{maxLength: 255}} variant="outlined"
                               placeholder="Erscheint nicht auf Dokumenten" className='textarea'
                               onChange={noteOnChangeHandler}/>
                    <TextField disabled={locked} size="small"
                               value={order ? order.description : ''} multiline maxRows={5}
                               InputLabelProps={{shrink: true}}
                               label='Beschreibung (maximal 2000 Zeichen)' inputProps={{maxLength: 2000}}
                               variant="outlined" placeholder="Erscheint am Anfang auf Dokumenten" className='textarea'
                               onChange={descriptionOnChangeHandler}/>
                    <TextField disabled={locked} size="small"
                               value={order ? order.endDescription : ''} multiline maxRows={5}
                               InputLabelProps={{shrink: true}}
                               label='Ende Beschreibung (maximal 2000 Zeichen)' inputProps={{maxLength: 2000}}
                               variant="outlined" placeholder="Erscheint am Ende auf Dokumenten" className='textarea'
                               onChange={endDescriptionOnChangeHandler}/>
                    {props.kind === 0 ? <Autocomplete
                        disabled={locked}
                        size="small"
                        value={order && order.assignedCustomer ? order.assignedCustomer.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                customerOnChangeHandler({
                                    name: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                customerOnChangeHandler({
                                    name: newValue.inputValue,
                                });
                                setIsNewCustomer(true);
                            } else {
                                customerOnChangeHandler(newValue);
                                setIsNewCustomer(false);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const {inputValue} = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Erstellen "${inputValue}"`,
                                });
                            }

                            return filtered.slice(0, 10);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="customers"
                        className='field'
                        options={props.customers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{ ...params.inputProps, maxLength: 45 }} label="Kunde"/>
                        )}
                    /> : null}
                    {props.kind === 0 && isNewCustomer ?
                        <div>
                            <TextField disabled={locked} size="small" className='field'
                                       label="Kundennummer" value={order.assignedCustomer && order.assignedCustomer.nr ? order.assignedCustomer.nr : ''}
                                       inputProps={{maxLength:45}}
                                       data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                       data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                       type="number"
                                       error={existingCustomer}
                                       helperText={existingCustomer ? "Kundennummer vergeben" : null}
                                       onWheel={(e) => e.target.blur()}
                                       onChange={customerNrOnChangeHandler}/>
                        </div> : null}
                    {props.kind === 1 ? <Autocomplete
                        disabled={locked}
                        size="small"
                        value={order && order.assignedSupplier ? order.assignedSupplier.name : ''}
                        onChange={(event, newValue) => {
                            if (typeof newValue === 'string') {
                                supplierOnChangeHandler({
                                    name: newValue,
                                });
                            } else if (newValue && newValue.inputValue) {
                                // Create a new value from the user input
                                supplierOnChangeHandler({
                                    name: newValue.inputValue,
                                });
                                setIsNewSupplier(true);
                            } else {
                                supplierOnChangeHandler(newValue);
                                setIsNewSupplier(false);
                            }
                        }}
                        filterOptions={(options, params) => {
                            const filtered = filter(options, params);

                            const {inputValue} = params;
                            // Suggest the creation of a new value
                            const isExisting = options.some((option) => inputValue === option.name);
                            if (inputValue !== '' && !isExisting) {
                                filtered.push({
                                    inputValue,
                                    name: `Erstellen "${inputValue}"`,
                                });
                            }

                            return filtered.slice(0, 10);
                        }}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        id="suppliers"
                        className='field'
                        options={props.suppliers}
                        getOptionLabel={(option) => {
                            // Value selected with enter, right from the input
                            if (typeof option === 'string') {
                                return option;
                            }
                            // Add "xxx" option created dynamically
                            if (option.inputValue) {
                                return option.inputValue;
                            }
                            // Regular option
                            return option.name;
                        }}
                        renderOption={(props, option) => <li {...props}>{option.name}</li>}
                        freeSolo
                        renderInput={(params) => (
                            <TextField size="small" {...params} inputProps={{ ...params.inputProps, maxLength: 45 }} label="Lieferant"/>
                        )}
                    /> : null}
                    {props.kind === 1 && isNewSupplier ?
                        <div>
                            <TextField disabled={locked} size="small" className='field'
                                       label="Lieferantennummer" value={order.assignedSupplier ? order.assignedSupplier.nr : null}
                                       data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                                       data-tooltip-content="Wird automatisch vergeben, wenn leer gelassen"
                                       inputProps={{maxLength:45}}
                                       type="number"
                                       onWheel={(e) => e.target.blur()}
                                       onChange={supplierNrOnChangeHandler}/>
                        </div> : null}
                    <TextField size="small" disabled={locked}
                               value={order && order.contactPersonBusinessPartner ? order.contactPersonBusinessPartner : ''}
                               InputLabelProps={{shrink: true}}
                               inputProps={{maxLength:50}}
                               label='Kontakt Person' className='field' onChange={contactPersonBusinessPartnerOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.contactEmailBusinessPartner ? order.contactEmailBusinessPartner : ''}
                               InputLabelProps={{shrink: true}}
                               inputProps={{maxLength:50}}
                               label='Kontakt E-Mail' className='field' onChange={contactEmailBusinessPartnerOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.contactPhoneNumberBusinessPartner ? order.contactPhoneNumberBusinessPartner : ''}
                               InputLabelProps={{shrink: true}}
                               inputProps={{maxLength:50}}
                               label='Kontakt Telefonnummer' className='field' onChange={contactPhoneNumberBusinessPartnerOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.commissionNr ? order.commissionNr : ''}
                               type="number"
                               onWheel={(e) => e.target.blur()}
                               data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                               data-tooltip-content={props.kind === 0 ? "Wird automatisch vergeben, wenn leer gelassen" : ""}
                               label='Kommissionsnummer' className='field'
                               inputProps={{maxLength:9}}
                               InputLabelProps={{shrink: true}}
                               onChange={commissionNrOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.orderNr ? order.orderNr : ''}
                               inputProps={{maxLength:255}}
                               InputLabelProps={{shrink: true}}
                               type="number"
                               data-tooltip-id="order-dialog-tooltip" data-tooltip-place="top"
                               data-tooltip-content={props.kind === 1 ? "Wird automatisch vergeben, wenn leer gelassen" : ""}
                               label='Bestellnummer' className='field' onChange={orderNrOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.orderDate ? order.orderDate : ''}
                               InputLabelProps={{shrink: true}}
                               label='Bestelldatum' type='date' className='field' onChange={orderDateOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.deliveryDate ? order.deliveryDate : ''}
                               InputLabelProps={{shrink: true}}
                               label='Lieferdatum' type='date' className='field' onChange={deliveryDateOnChangeHandler}/>
                    <TextField size="small" disabled={locked}
                               value={order && order.item ? order.item : ''}
                               inputProps={{maxLength:255}}
                               InputLabelProps={{shrink: true}}
                               label='Gegenstand' className='field' onChange={itemOnChangeHandler}/>
                    {order && order.deliveryDate ?
                        <div className="field-holder">
                            <TextField disabled={props.locked} size="small"
                                       value={order ? order.duration : ''}
                                       onChange={orderDurationOnChangeHandler}
                                       inputProps={{maxLength:9}}
                                       className='field small-position-field'
                                       type='number'
                                       onWheel={(e) => e.target.blur()}
                                       label='Dauer'
                            />
                        </div> : null}
                </div>
                <div className="position-content-holder">
                    <div className="position-holder">
                        <FormControlLabel
                            className='switch-label'
                            control={
                                <Switch className='switch position-display-switch' checked={positionDisplay}
                                        onChange={togglePositionDisplay} color="primary"/>
                            }
                            label={positionDisplayText}
                        />
                        <div className="positions">
                            {positionDisplay && order && order.documentPositions ? order.documentPositions.map((item, index) => (
                                <DocumentPosition
                                    key={item.frontendId ? item.frontendId : item.id}
                                    index={index}
                                    type="ORDER"
                                    kind={props.kind}
                                    user={props.user}
                                    documentPosition={item}
                                    order={order}
                                    locked={locked}
                                    moveDocumentPositionUp={moveDocumentPositionUp}
                                    moveDocumentPositionDown={moveDocumentPositionDown}
                                    documentPositionStateOnChangeHandler={documentPositionStateOnChangeHandler}
                                    durationOnChangeHandler={durationOnChangeHandler}
                                    offerPositionOnChangeHandler={offerPositionOnChangeHandler}
                                    guidePriceOnChangeHandler={guidePriceOnChangeHandler}
                                    articleOnChange={orderPositionArticleOnChange}
                                    articleNrOnChangeHandler={orderPositionArticleNrOnChangeHandler}
                                    inventoryCountOnChange={orderPositionInventoryCountOnChange}
                                    unitOnChange={orderPositionUnitOnChange}
                                    inventoryCountInvoiceOnChange={orderPositionInventoryCountInvoiceOnChange}
                                    inventoryCountDeliveryBillOnChange={orderPositionInventoryCountDeliveryBillOnChange}
                                    piecePriceOnChange={orderPositionPiecePriceOnChange}
                                    positionVatOnChange={orderPositionVatOnChange}
                                    removeOrderPosition={removeOrderPosition}
                                    descriptionOnChangeHandler={positionDescriptionOnChangeHandler}
                                    hintOnChangeHandler={positionHintOnChangeHandler}
                                    loadLastPrice={orderPositionLoadLastPrice}
                                    articles={props.articles}
                                    company={props.company}
                                    addBatch={addBatch}
                                    batchNrOnChangeHandler={batchNrOnChangeHandler}
                                    batchMaterialOnChangeHandler={batchMaterialOnChangeHandler}
                                    batchAddFactoryCertificate={batchAddFactoryCertificate}
                                    batchRemoveFactoryCertificate={batchRemoveFactoryCertificate}
                                    batchToggleFactoryCertificateADW2={batchToggleFactoryCertificateADW2}
                                    batchFactoryCertificateFolderStorageOnChangeHandler={batchFactoryCertificateFolderStorageOnChangeHandler}
                                    batchFactoryCertificateFileOnChangeHandler={batchFactoryCertificateFileOnChangeHandler}
                                    removeBatch={removeBatch}
                                    showSendMailDialog={showSendMailDialog}
                                    showOriginalFileDialog={showOriginalFileDialog}
                                    showEditedFileDialog={showEditedFileDialog}
                                    showPDFDialog={showPDFDialog}
                                    showDeleteFileDialog={showDeleteFileDialog}
                                    showDeleteOriginalFileDialog={showDeleteOriginalFileDialog}
                                    showDeleteEditedFileDialog={showDeleteEditedFileDialog}
                                    addEmailAttachment={addEmailAttachment}
                                    emailAttachments={props.emailAttachments}
                                    noteOnChangeHandler={positionNoteOnChangeHandler}
                                    deliveryDateOnChangeHandler={positionDeliveryDateOnChangeHandler}
                                />
                            )) : null}
                            {!positionDisplay && order && order.documentPositions ?
                                <div>
                                    {positionIndex !== null && order.documentPositions[positionIndex] ?
                                        <div className="position-display-holder">
                                            <ul className="position-navigation-holder">
                                                <li className="navigation-item animate"
                                                    onClick={firstPositionOnClickHandler}>
                                                    <FirstPageIcon className="position-navigation-icon"/>
                                                </li>
                                                <li className="navigation-item animate"
                                                    onClick={prevPositionOnClickHandler}>
                                                    <KeyboardArrowLeftIcon className="position-navigation-icon"/>
                                                </li>
                                                <li className="navigation-item animate"
                                                    onClick={nextPositionOnClickHandler}>
                                                    <KeyboardArrowRightIcon className="position-navigation-icon"/>
                                                </li>
                                                <li className="navigation-item animate"
                                                    onClick={lastPositionOnClickHandler}>
                                                    <LastPageIcon className="position-navigation-icon"/>
                                                </li>
                                            </ul>
                                            <DocumentPosition
                                                index={positionIndex}
                                                kind={props.kind}
                                                type="ORDER"
                                                user={props.user}
                                                documentPosition={order.documentPositions[positionIndex]}
                                                order={order}
                                                locked={locked}
                                                moveDocumentPositionUp={(e) => moveDocumentPositionUp(e, positionIndex)}
                                                moveDocumentPositionDown={moveDocumentPositionDown}
                                                documentPositionStateOnChangeHandler={documentPositionStateOnChangeHandler}
                                                durationOnChangeHandler={durationOnChangeHandler}
                                                offerPositionOnChangeHandler={offerPositionOnChangeHandler}
                                                articleOnChange={orderPositionArticleOnChange}
                                                articleNrOnChangeHandler={orderPositionArticleNrOnChangeHandler}
                                                inventoryCountOnChange={orderPositionInventoryCountOnChange}
                                                unitOnChange={orderPositionUnitOnChange}
                                                inventoryCountInvoiceOnChange={orderPositionInventoryCountInvoiceOnChange}
                                                inventoryCountDeliveryBillOnChange={orderPositionInventoryCountDeliveryBillOnChange}
                                                piecePriceOnChange={orderPositionPiecePriceOnChange}
                                                positionVatOnChange={orderPositionVatOnChange}
                                                removeOrderPosition={removeOrderPosition}
                                                descriptionOnChangeHandler={positionDescriptionOnChangeHandler}
                                                hintOnChangeHandler={positionHintOnChangeHandler}
                                                loadLastPrice={orderPositionLoadLastPrice}
                                                articles={props.articles}
                                                company={props.company}
                                                addBatch={addBatch}
                                                batchNrOnChangeHandler={batchNrOnChangeHandler}
                                                batchMaterialOnChangeHandler={batchMaterialOnChangeHandler}
                                                batchAddFactoryCertificate={batchAddFactoryCertificate}
                                                batchRemoveFactoryCertificate={batchRemoveFactoryCertificate}
                                                batchToggleFactoryCertificateADW2={batchToggleFactoryCertificateADW2}
                                                batchFactoryCertificateFolderStorageOnChangeHandler={batchFactoryCertificateFolderStorageOnChangeHandler}
                                                batchFactoryCertificateFileOnChangeHandler={batchFactoryCertificateFileOnChangeHandler}
                                                removeBatch={removeBatch}
                                                showSendMailDialog={showSendMailDialog}
                                                showOriginalFileDialog={showOriginalFileDialog}
                                                showEditedFileDialog={showEditedFileDialog}
                                                showPDFDialog={showPDFDialog}
                                                showDeleteFileDialog={showDeleteFileDialog}
                                                showDeleteOriginalFileDialog={showDeleteOriginalFileDialog}
                                                showDeleteEditedFileDialog={showDeleteEditedFileDialog}
                                                addEmailAttachment={addEmailAttachment}
                                                emailAttachments={props.emailAttachments}
                                                noteOnChangeHandler={positionNoteOnChangeHandler}
                                                deliveryDateOnChangeHandler={positionDeliveryDateOnChangeHandler}
                                            />
                                        </div> : <p className="position-info">Die Bestellung hat keine Positionen</p>}
                                </div> : null}
                        </div>
                    </div>
                    <div className='button-holder'>
                        <ToolButton disabled={locked} className='field' onClick={addOrderPosition}>Position
                            hinzufügen</ToolButton>
                        <ToolButton main disabled={locked} className='field'
                                    onClick={saveOrderOnClickHandler}>Speichern</ToolButton>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default OrderDialog;